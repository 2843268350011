import { React, useState, useContext, createContext } from 'react'

// Create a context for authentication
const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
  // Simulate authentication status and user role
  const [isAuthenticated, setIsAuthenticated] = useState(true) // true = logged in, false = not
  const [token, setToken] = useState('valid-token') // Simulated token
  const [userRole, setUserRole] = useState('admin') // Either 'admin' or 'user'

  return (
    <AuthContext.Provider value={{ isAuthenticated, token, userRole }}>
      {children}
    </AuthContext.Provider>
  )
}

// Custom hook to use the Auth context
export const useAuth = () => {
  return useContext(AuthContext)
}
