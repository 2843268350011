import React, { Suspense, useEffect } from 'react'
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { jwtDecode } from 'jwt-decode'
import { CSpinner, useColorModes } from '@coreui/react'
import './scss/style.scss'

// Containers
// Fake Auth Service to simulate login and store JWT in localStorage
const AuthService = {
  getToken: () => localStorage.getItem('token'),
  logout: () => localStorage.removeItem('token'),
  sessionExpired: () => {
    const token = localStorage.getItem('token')
    if (token) {
      const decodedToken = jwtDecode(token)
      const currentTime = Date.now() / 1000 // Get current time in seconds
      return decodedToken.exp > currentTime
    } else {
      return false
    }
  },
  getRole: () => {
    const token = localStorage.getItem('token')
    if (token) {
      const decoded = JSON.parse(atob(token.split('.')[1]))
      return decoded.role
    }
    return null
  },
}
//import PrivateRoute from './PrivateRoute'
// Private Route Component
const PrivateRoute = ({ roles, children }) => {
  const role = AuthService.getRole()
  const isExpiredToken = AuthService.sessionExpired()
  if (!role) return <Navigate to="/login" />
  if (!isExpiredToken) return <Navigate to="/login" />
  if (!roles.includes(role)) return <Navigate to="/" />
  return children
}
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const DefaultLayoutAdmin = React.lazy(() => import('./layout/DefaultLayoutAdmin'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const { isColorModeSet, setColorMode } = useColorModes('coreui-free-react-admin-template-theme')
  const storedTheme = useSelector((state) => state.theme)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1])
    const theme = urlParams.get('theme') && urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0]
    if (theme) {
      setColorMode(theme)
    }

    if (isColorModeSet()) {
      return
    }

    setColorMode(storedTheme)
  }, [])

  return (
    <HashRouter>
      <Suspense
        fallback={
          <div className="pt-3 text-center">
            <CSpinner color="primary" variant="grow" />
          </div>
        }
      >
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/register23092024" name="Register Page" element={<Register />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route
            path="/admin/*"
            name="HomeAdmin"
            element={
              <PrivateRoute roles={['admin']}>
                <DefaultLayoutAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="/user/*"
            name="Home"
            element={
              <PrivateRoute roles={['user', 'admin']}>
                <DefaultLayout />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
